import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    users: [
      {
        userId: 0,
        userName: 'Сергей',
        userSurname: 'Морозов',
        userBirthDate: '2021-04-05',
        userWeight: "",
        userRank: 'Инструктор',
        userPhone: '8(495)967-34-56',
        userEmail: 'morozov@mail.com',
        userPassword: '1234',
        userImage: require('../assets/morozov-small.webp'),
        userDescription: 'Повелитель',
        userCompetitions: [],
        userBelt: 'синий',
        userIsAdmin: true,
        userInstructor: ''
      },
      {
        userId: 1,
        userName: 'Sub-Zero',
        userSurname: '',
        userBirthDate: '2021-04-05',
        userWeight: "96",
        userRank: 'Бывалый',
        userPhone: '8(495)967-34-56',
        userEmail: 'sub-zero@mail.com',
        userPassword: '1234',
        userImage: require('../assets/avatar-1.jpg'),
        userDescription: 'Опаснейший боец, повелевающий стихией холода. Умеет замораживать своих противников, а затем разбивать их на мелкие кусочки. Любит пиццу с морепродуктами =)',
        userCompetitions: [
          {
            name: 'Mortal Kombat',
            place: 'Убит'
          },
          {
            name: 'Открытый Чемпионат МО по снежкам',
            place: '1 место'
          }
        ],
        userBelt: 'синий',
        userInstructor: ''
      },
      {
        userId: 2,
        userName: 'Scorpion',
        userSurname: '',
        userBirthDate: '2021-04-05',
        userWeight: "95",
        userRank: 'Балбес',
        userPhone: '8(495)967-33-55',
        userEmail: 'scorpion@mail.com',
        userPassword: '1234',
        userImage: require('../assets/avatar-4.jpg'),
        userDescription: 'Умеет пускать змейку из руки и подтягивать противника к себе, громко кричит: "Get Over Here!"',
        userCompetitions: [
          {
            name: 'Mortal Kombat',
            place: 'Убит'
          },
          {
            name: 'Чемпионат Балашихи по перетягиванию каната',
            place: '2 место'
          }
        ],
        userBelt: 'желтый',
        userInstructor: ''
      },
      {
        userId: 3,
        userName: 'Reptile',
        userSurname: '',
        userBirthDate: '2021-04-05',
        userWeight: "105",
        userRank: 'Трус',
        userPhone: '8(495)967-33-55',
        userEmail: 'reptile@mail.com',
        userPassword: '1234',
        userImage: require('../assets/avatar-5.jpg'),
        userDescription: 'Токсичный рептилоид',
        userCompetitions: [
          {
            name: 'Mortal Kombat',
            place: 'Убит'
          },
          {
            name: 'Чемпионат Люберец по плевкам в длину',
            place: '3 место'
          }
        ],
        userBelt: 'зеленый',
        userInstructor: 'Morozov'
      }
    ],
    user: {
      userId: 0,
      userName: '',
      userSurname: '',
      userBirthDate: null,
      userWeight: 85,
      userPhone: '',
      userEmail: "",
      userPassword: "",
      userDescription: 'Укажите дополнительную информацию о себе',
      userInstructor: 'Укажите своего тренера / инструктора'
    },
    competitions: [
      {
        name: 'Чемпионат по скороговоркам',
        participants: []
      }
    ],
    instructors: ['Морозов С.В.', 'Райвич М.Л.']
  },
  getters: {
    isAuthenticated(state) {
      if (state.user.userName) {
        return true;
      }
      return false;
    }
  },
  mutations: {
    loginUser(state, user) {
      state.user = user;
    },
    registrationFormSubmit(state, data) {
      if (state.user.userName) {
        for (let i = 0; state.users.length-1; i++) {
          if (state.users[i].userId == data.userId) {
            state.users[i] = data;
            state.user = data;
            return true;
          }
        }
      } else {
        data.userId = state.users[state.users.length-1].userId + 1;
        state.user = data;
        state.users.push(data);
        return true;
      }
    },
     studentFormEdit(state, data) {
      state.users.forEach(user => {
        if (user.userId === data.userId) {
          user = data;
        }
      })
      return;
    },
    addCompetitor(state, data) {
      state.competitions.forEach(competition => {
        if (competition.name === data.name && !competition.participants.includes(state.user)) {
          competition.participants.push(state.user);
          state.user.userCompetitions.push({name: data.name, place: 'Участник'});
        }
        return;
      })
    },
    userLogout(state) {
      state.user = {
        userId: 0,
        userName: '',
        userSurname: '',
        userBirthDate: null,
        userWeight: 85,
        userPhone: '',
        userEmail: "",
        userPassword: "",
        userDescription: 'Укажите дополнительную информацию о себе',
        userInstructor: 'Укажите своего тренера / инструктора'
      };
    }
  },
  actions: {
    editFormSubmit(context, credentials) {
      context.commit('registrationFormSubmit', credentials);
      return true;
    },
    studentFormEdit(context, credentials) {
      context.commit('studentFormEdit', credentials);
      return true;
    }
  },
  modules: {
  }
})
