<template>
  <v-card>
    <v-footer padless color="red">
      <v-card flat width="100%" color="red">
        <v-divider></v-divider>
        <v-card-text class="d-flex justify-center white--text">
          {{ new Date().getFullYear() }} <span class="ml-2"></span><strong>ILMMA &copy;</strong>
        </v-card-text>
      </v-card>
    </v-footer>


  </v-card>
</template>

<script>
export default {
  data: () => ({
    //
  }),
}
</script>