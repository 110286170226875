import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    // children: [
    //   {
    //     path: 'edit',
    //     name: 'Edit',
    //     component: () => import(/* webpackChunkName: "edit" */ '../views/Edit.vue')
    //   },
    //   {
    //     path: 'childreg',
    //     name: 'ChildRegistration',
    //     component: () => import(/* webpackChunkName: "childreg" */ '../views/ChildRegistration.vue')
    //   },
    // ],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == "Register" || to.name == "Login" || to.name == 'Contacts') { next() }
  else if (to.name !== 'Home' && !store.getters.isAuthenticated) { next({ name: 'Home' }) }
  else next()
})


export default router
