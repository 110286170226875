<template>
  <nav>
    <v-toolbar class="mb-4" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-avatar size="36px" class="mx-2"><img src="../assets/bg_logo.jpg"
        alt="ILMMA"
      ></v-avatar>
      <v-toolbar-title class="font-weight-bold">ILMMA</v-toolbar-title>
      <v-card v-if="$store.state.user.userId" dark flat class="d-flex justify-end align-center">
        <v-card v-if="$vuetify.breakpoint.mdAndUp" class="ml-12 body-1">
          Добро пожаловать, {{ $store.state.user.userName }}!
        </v-card>
      </v-card>

      <v-spacer></v-spacer>
      <div class="red pa-1 pa-md-2">
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn @click="userLogout" v-if="isAuthenticated" icon>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
        <v-btn :to="{name: 'Login'}" icon v-if="!isAuthenticated">
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" absolute temporary height="100vh">
      <v-list nav>
        <v-list-item-group
          v-model="group"
          active-class="red--text text--accent-4">

          <router-link style="text-decoration: none; color: inherit;" :to="{name: 'Home'}">
          <v-list-item >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
              <v-list-item-title>
                Главная
            </v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link style="text-decoration: none; color: inherit;"
          v-if="$store.getters.isAuthenticated" :to="{name: 'Profile'}">
          <v-list-item >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Профиль
            </v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link style="text-decoration: none; color: inherit;"
          v-if="$store.getters.isAuthenticated" :to="{name: 'Admin'}">
          <v-list-item >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Администрирование
            </v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link style="text-decoration: none; color: inherit;" :to="{name: 'Contacts'}">
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-phone-in-talk</v-icon>
          </v-list-item-icon>
            <v-list-item-title>
              Обратная связь
          </v-list-item-title>
        </v-list-item>
      </router-link>


        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </nav>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    userLogout() {
      this.$store.commit('userLogout');
      this.$router.push('Home');
    },
    clear() { this.group = null }
  },
  watch: {
    group() {
      this.drawer = false
    },
  }
}
</script>
